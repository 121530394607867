import { Suspense, lazy } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const Login = lazy(() => import('./pages/Login'));
const Home = lazy(() => import('./pages/Home'));

export default function App() {
	const { isLoading, isAuthenticated, error } = useAuth0();

	if (isLoading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>Oops... {error.message}</div>;
	}

	if (isAuthenticated) {
		return (
			<BrowserRouter>
				<Suspense fallback={<></>}>
					<Routes>
						<Route path="/*" element={<Home />} />
					</Routes>
				</Suspense>
			</BrowserRouter>
		);
	} else {
		return <Login />
	}
}
